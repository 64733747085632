





































































































































import Vue from 'vue';
import moment from 'moment';
import { Tag } from '@/interface';
import { message } from 'ant-design-vue';
import * as api from '@/api/certificationAudit';
export default Vue.extend({
  name: 'certificationAuditDetails',
  data() {
    return {
      submitLoading: false,
      enterpriseInfo: {
        sysUserBaseInfoDTO: {},
      },
      previewVisible: false,
      previewImage: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        auditStatus: 1,
        auditRemark: '',
        agencyCode: '',
      },
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getPersonalTenantDetail(this.$route.query.id);
    }
  },
  methods: {
    moment,
    getPersonalTenantDetail(agencyCode) {
      api.getPersonalTenantDetail(agencyCode).then((res) => {
        if (res.status === 200) {
          // console.log(res.result);
          this.enterpriseInfo = res.result;
          this.form.auditStatus =
            res.result.auditStatus == 0 ? 1 : res.result.auditStatus;
          this.form.auditRemark = res.result.auditRemark;
        } else {
          message.error(res.message);
        }
      });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(imageUrl: string) {
      this.previewImage = imageUrl;
      this.previewVisible = true;
    },
    onSubmit() {
      // console.log('submit!', this.form);
      (this.$refs.auditForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.form.agencyCode = this.$route.query.id + '';
          api
            .personalAuditSubmit(this.form)
            .then((res) => {
              if (res.status === 200) {
                // console.log(res.result);
                if (res.result) {
                  this.closeCurrentTags(
                    '/platform/certificationAudit/personalDetails',
                    '/platform/certificationAudit'
                  );
                }
              }
            })
            .finally(() => {
              this.submitLoading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onChange(e) {
      // console.log('radio checked', e.target.value);
      this.form.auditRemark = ''; // 清空备注
    },
    closeCurrentTags(currentPath: string, toPath: string) {
      // 关闭当前tag,并跳转到指定页面
      const filterTags = this.$store.state.common.tags.filter(
        (tag: Tag) => tag.path !== currentPath
      );
      this.$store.commit('SET_TAGS', filterTags);
      this.$router.push({ path: toPath });
    },
  },
});
